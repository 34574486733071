/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class TicketSelectorComponent
 * @description
 * Ticket Selector Component
 * Created by Carlos.Moreira @ 2020/01/19
 */
// Angular Components
import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { share, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

// Third party Components
import { NgSelectComponent } from '@ng-select/ng-select';

// Ticket Tracker Components
import { BaseEntityCustomization } from '../base-entity-customization/base-entity-customization';
import { EntityCustomizationType } from '../../../core/enums/customization/entity-customization-type.enum';

// Libraries Components
import { StoreList, TicketTrackerTicket, OperationalEntity, TicketTrackerSettings } from 'qore.models';
import { MultiLanguage, isNullOrUndefined, TicketUtilities } from 'qevo.utilities';
import { PaginatedList } from 'qevo.models';

@Component({
	selector: 'qoala-tt-ticket-selector',
	templateUrl: './ticket-selector.component.html',
	styleUrls: ['./ticket-selector.component.scss']
})
export class TicketSelectorComponent extends BaseEntityCustomization implements OnInit {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	 */

	// List of entities
	availableEntities: OperationalEntity[];
	loadingEntities: boolean;
	@ViewChild('entitiesSelect') entitiesSelect: NgSelectComponent;

	// List of entity stores
	availableStores: StoreList[] = [];
	private _storesNextPageIndex: number; // -1 means that no more pages exist (no more records)
	private _storeSearchTerm: string;
	loadingStores: boolean;
	@ViewChild('storesSelect') storesSelect: NgSelectComponent;

	// Stores search term value changed ... to debounce the search ...
	private _storesSearchDelayTermChanged: Subject<any> = new Subject<any>();

	// List of store tickets
	availableTickets: TicketTrackerTicket[] = [];
	public _ticketsNextPageIndex: number; // -1 means that no more pages exist (no more records)
	private _ticketSearchTerm: string;
	loadingTickets: boolean;
	@ViewChild('ticketsSelect') ticketsSelect: NgSelectComponent;

	// Tickets search term value changed ... to debounce the search ...
	private _ticketsSearchDelayTermChanged: Subject<any> = new Subject<any>();

	// User selection EntityId > StoreId > TicketId
	entityId: number;
	entityName: string;
	entityLogo: SafeUrl;

	storeId: number;
	storeName: string;

	ticketUniqueId: string;
	ticketFormatted: string;

	// Last user full search
	hasLastFullSearchInfo: boolean;

	private _lastEntityId: number;
	private _lastStoreName: string; 		// NOT IN USE AT THIS TIME
	private _lastTicketFormatted: string; 	// NOT IN USE AT THIS TIME

	// Delay in milliseconds to close the dropdown after exact search match or only item
	private _timeoutToCloseDropdownDelay = 500;

	// UI function exports
	isNullOrUndefined: typeof isNullOrUndefined = isNullOrUndefined;

	// Disable search to avoid showing mobile keyboard
	// Added by Carlos.Moreira @ 2020/02/24
	public disableSearch = false;

	// Settings object
	ticketTrackerSettings: TicketTrackerSettings = null;
	termsAndConditionsUrl: string = null;

	// Set new entity selection menu
	public showEntityMenu = true;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	 */
	constructor(injector: Injector) {
		super('TicketSelectorComponent', injector, EntityCustomizationType.Tickets);
	}

	ngOnInit() {
		// 1. Default values
		// this.disableSearch = this._ticketTrackerService.disableSearch();
		this.disableSearch = true;

		// 4. Add watches

		// 4.1. Watch for stores search term changes
		this._storesSearchDelayTermChanged
			.pipe(
				map(event$ => event$.term),
				debounceTime(600), // wait 600ms after the last event before emitting last event
				distinctUntilChanged() // only emit if value is different from previous value
			).subscribe((searchTerm: string) => {
				// Force search if exists a previous search term used and:
				//  - user has cleaned the dropdown box
				//  - is deleting characters in previous search and there are less than 2 characters
				// So that way we refresh the list with a empty search
				// Modified by Carlos.Moreira @ 2020/04/27
				const forceSearch: boolean = !isNullOrUndefined(this._storeSearchTerm) &&
					(!isNullOrUndefined(searchTerm) &&
						(this._storeSearchTerm.startsWith(searchTerm) && searchTerm.trim().length <= this._storeSearchTerm.trim().length)
					);

				// console.log('->', 'Last search term', this._storeSearchTerm, 'New search term:', searchTerm,
				// 	'User is cleaning stuff ?', forceSearch);

				if ((!isNullOrUndefined(searchTerm) && searchTerm.length >= 2) || forceSearch) {
					this.loadStores(searchTerm);
				}
			});

		// 4.3. Watch for tickets search term changes
		this._ticketsSearchDelayTermChanged
			.pipe(
				map(event$ => event$.term),
				debounceTime(600), // wait 600ms after the last event before emitting last event
				distinctUntilChanged() // only emit if value is different from previous value
			).subscribe(searchTerm => {
				// Force search if exists a previous search term used and:
				//  - user has cleaned the dropdown box
				//  - is deleting characters in previous search and there are less than 2 characters
				// So that way we refresh the list with a empty search
				// Modified by Carlos.Moreira @ 2020/04/27
				const forceSearch: boolean = !isNullOrUndefined(this._ticketSearchTerm) &&
					(!isNullOrUndefined(searchTerm) &&
						(this._ticketSearchTerm.startsWith(searchTerm) && searchTerm.trim().length <= this._ticketSearchTerm.trim().length)
					);

				// console.log('->', 'Last search term', this._ticketSearchTerm, 'New search term:', searchTerm,
				// 	'User is cleaning stuff ?', forceSearch);

				if ((!isNullOrUndefined(searchTerm) && searchTerm.length >= 2) || forceSearch) {
					this.loadTickets(searchTerm);
				}
			});
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	 */


	/**
	 * ================================================================================================================================
	 * Navigation
	 * ================================================================================================================================
	 */

	/**
	 * Goes to the ticket tracking page
	 */
	gotoTicketTracking() {
		// Save last full search
		this.setLastFullSearchInfo();

		this._ticketTrackerService.gotoPage([this.ticketUniqueId]);
	}

	/**
	 * Back button event to go to the entity selection
	 */
	goBackToEntitySelection() {
		// Clear variables selected
		this.storeId = null;
		this.entityId = null;
		this._cookieService.set('QoalaTicketTrackerNewTicketEntity', null);

		// Clear Customization
		this.clearCustomization();

		// Reset Ticket Tracker Settings at the service
		this.ticketTrackerSettings = null;
		this._ticketTrackerService.setTicketTrackerSettings(null);
		this._ticketTrackerService.setTermsAndConditionsUrl(null);
		this.showEntityMenu = true;
	}

	/**
	 * ================================================================================================================================
	 * Lists
	 * ================================================================================================================================
	 */

	/**
	 * Event when the user selects/changes an item
	 * @param step step id
	 * @param item item selected
	 */
	itemSelected(step: number, item: any) {
		if (step === 2) {
			this.ticketUniqueId = null;
			this.ticketFormatted = null;

			// Second Step .. save id and load stuff for next step (if item is not undefined)
			if (isNullOrUndefined(item)) {
				this.storeId = null;
				this.storeName = null;

				this.availableStores = [];
				this._storesNextPageIndex = null;
				this._storeSearchTerm = null;

				this.availableTickets = [];
				this._ticketsNextPageIndex = null;
				this._ticketSearchTerm = null;

			} else {
				this.storeId = item.id;
				this.storeName = item.name;

				this.availableTickets = [];
				this._ticketsNextPageIndex = null;
				this._ticketSearchTerm = null;
				this.loadTickets(); // this.hasLastFullSearchInfo ? this._lastTicketFormatted : null);
			}

		} else {
			// Third Step ... save id and all done (if item is not undefined)
			if (isNullOrUndefined(item)) {
				this.ticketUniqueId = null;
				this.ticketFormatted = null;

				this.availableTickets = [];
				this._ticketsNextPageIndex = null;
				this._ticketSearchTerm = null;
			} else {
				this.ticketUniqueId = item.ticketUniqueId;
				this.ticketFormatted = item.ticketFormated;
			}

			// Reset the flag so that the last full search is only done once
			// this.hasLastFullSearchInfo = false;
		}
	}

	/**
	 * Fire when the ng-select is open
	 * @param step step id
	 */
	selectOpen(step: number) {
		if (step === 2) {
			if (isNullOrUndefined(this.storeId)) {
				this.availableStores = [];
				this.clearStore();
			}
		} else {
			if (isNullOrUndefined(this.ticketUniqueId)) {
				this.availableTickets = [];
				this.clearTicket();
			}
		}

	}

	/**
	 * Fire when the ng-select loses focus (blur)
	 * @param step step id
	 */
	selectBlur(step: number) {
		if (step === 2) {
			this._storeSearchTerm = null;
		} else {
			this._ticketSearchTerm = null;
		}
	}

	/**
	 * Selected Entity Event
	 * @param entity
	 */
	selectedEntity(entity: OperationalEntity) {
		this.showEntityMenu = false;
		this.entityId = entity.id;
		this.entityLogo = entity.publicLogo;
		this.entityName = entity.name;

		if (!isNullOrUndefined(entity.settings)) {
			// Read JSON
			this.ticketTrackerSettings = JSON.parse(entity.settings as string).ticketTrackerSettings;
			this.termsAndConditionsUrl = JSON.parse(entity.settings as string).termsAndConditionsUrl;

			// Apply customization (assigning new ticket tracker settings to be saved in service)
			this.applyCustomization(this.ticketTrackerSettings, true);

			// Save terms and conditions configured
			this._ticketTrackerService.setTermsAndConditionsUrl(this.termsAndConditionsUrl);
		}
	}

	/**
	 * Search Stores (server and client side)
	 * @param event$ search term
	 */
	searchStores(event$: any) {
		this._storesSearchDelayTermChanged.next(event$);
	}

	/**
	 * Clear Store selected button pressed
	 */
	clearStore() {
		this.storeId = null;
		this.storeName = null;

		this.ticketUniqueId = null;
		this.ticketFormatted = null;

		this.availableTickets = [];
		this._ticketsNextPageIndex = null;
		this._ticketSearchTerm = null;
		this._storesNextPageIndex = null;

		this.loadStores();
	}

	/**
	 * Load Stores
	 * @param search Search term to filter stores
	 */
	loadStores(searchTerm?: string) {
		// Is search?
		if (isNullOrUndefined(searchTerm)) {
			this._storeSearchTerm = null;

			// No more stores (so no need to go to server)
			if (this._storesNextPageIndex === -1) { return; }
		} else {

			this._storesNextPageIndex = null;
			this._storeSearchTerm = searchTerm;
			this.availableStores = [];

			this.storeName = null;
			this.storeId = null;
		}

		this.loadingStores = true;

		this._logger.debug(`${this.componentName}:loadStores`, 'Entity Id', this.entityId,
			'Search Term is', this._storeSearchTerm, 'Next Page is', this._storesNextPageIndex);

		// Get information from the database
		// eslint-disable-next-line max-len
		this._ticketTrackerService.tickets.getListOfStores(this.entityId, this._storeSearchTerm, this._storesNextPageIndex)
			.pipe(share())
			.subscribe(
				(result: PaginatedList<StoreList>) => {
					this._logger.info(`${this.componentName}:loadStores`, 'Entity Id', this.entityId, 'Result returned', result);

					// First remove items that already exist (avoids duplicates)
					// Added by Carlos.Moreira @ 2021/05/11
					// Now checking if results are for the current entity selected
					// Added by André.Pinho @ 2021/08/04
					const resultFiltered: StoreList[] = result.items.filter(storeFilter =>
						isNullOrUndefined(this.availableStores) || this.availableStores.length === 0 ?
							storeFilter.entityId === this.entityId : !this.availableStores.some(store => store.id === storeFilter.id));

					// First reorder multi-language array before sending it to UI
					// Added by Carlos.Moreira @ 2019/04/24
					this.availableStores = MultiLanguage.reOrder(this.availableStores.concat(resultFiltered), 'name', this.curLangCode,
						false, true, true);

					// Assign current page index of stores
					this._storesNextPageIndex = result.hasNextPage ? result.pageIndex + 1 : -1;

					// If only one store ... fill the next step :)
					if (this.availableStores.length === 1) {
						// If item is selected close dropdown
						setTimeout(() => {
							this.storesSelect.close();
							this.ticketsSelect.focus();
						}, this._timeoutToCloseDropdownDelay);

						this.itemSelected(2, this.availableStores[0]);
					}

					// if is loading last full search information and the store is found ... must reset setting and reload stuff
					// if (this.hasLastFullSearchInfo && this.availableStores.length === 0) {
					// 	this.hasLastFullSearchInfo = false;
					// 	this.loadStores('');
					// }
				},
				error => {
					this._logger.error(`${this.componentName}:loadStores`, 'Entity Id', this.entityId, 'Result returned', error);
					this.availableStores = [];
					this._storesNextPageIndex = null;
					this._storeSearchTerm = null;

					this.loadingStores = false;
				},
				() => {
					this.loadingStores = false;
				}
			);
	}

	/**
	 * Search Tickets (server and client side)
	 * @param event$ search term
	 */
	searchTickets(event$: any) {
		this._ticketsSearchDelayTermChanged.next(event$);
	}

	/**
	 * Clear Ticket selected button pressed
	 */
	clearTicket() {
		this.ticketUniqueId = null;
		this.ticketFormatted = null;
		this._ticketsNextPageIndex = null;

		this.loadTickets();
	}

	/**
	 * Load Tickets
	 */
	loadTickets(searchTerm?: string) {
		// Is search?
		if (isNullOrUndefined(searchTerm)) {
			this._ticketSearchTerm = null;

			// No more tickets (so no need to go to server)
			if (this._ticketsNextPageIndex === -1) { return; }
		} else {

			this._ticketsNextPageIndex = null;
			this._ticketSearchTerm = searchTerm;
			this.availableTickets = [];
		}

		this.loadingTickets = true;

		this._logger.debug(`${this.componentName}:loadTickets`, 'Entity Id', this.entityId,
			'Store Id', this.storeId,
			'Search Term is', this._storeSearchTerm, 'Next Page is', this._storesNextPageIndex);

		// Get information from the database
		// eslint-disable-next-line max-len
		this._ticketTrackerService.tickets.getListOfTickets(this.entityId, this.storeId, this._ticketSearchTerm, this._ticketsNextPageIndex)
			.pipe(share())
			.subscribe(
				result => {
					this._logger.info(`${this.componentName}:loadTickets`, 'Entity Id', this.entityId,
						'Store Id', this.storeId, 'Result returned', result);

					// First remove items that already exist (avoids duplicates)
					// Added by Carlos.Moreira @ 2021/05/11
					const resultFiltered: TicketTrackerTicket[] = result.items.filter(ticketFilter =>
						isNullOrUndefined(this.availableTickets) || this.availableTickets.length === 0 ?
							true : !this.availableTickets.some(ticket => ticket.ticketUniqueId === ticketFilter.ticketUniqueId));

					// Format tickets received
					resultFiltered.forEach(ticket => {
						// Show the real "Ticket Formated" or the "Name To Display" if it exists
						if (!isNullOrUndefined(ticket.info)) {
							ticket.ticketFormated = TicketUtilities.getTicketInfoToDisplay(ticket.ticketFormated, ticket.info as string);
						}
					});

					this.availableTickets = this.availableTickets.concat(resultFiltered);

					// Assign current page index of tickets
					this._ticketsNextPageIndex = result.hasNextPage ? result.pageIndex + 1 : -1;

					// If only one ticket ... fill the next step :)
					if (this.availableTickets.length === 1) {
						// If item is selected close dropdown
						setTimeout(() => {
							this.ticketsSelect.close();
						}, this._timeoutToCloseDropdownDelay);

						this.itemSelected(3, this.availableTickets[0]);
					}

					// if is loading last full search information and the ticket isn't found ... must reset setting and reload stuff
					// if (this.hasLastFullSearchInfo && this.availableTickets.length === 0) {
					// 	this.hasLastFullSearchInfo = false;
					// 	this.loadTickets('');
					// }
				},
				error => {
					this._logger.error(`${this.componentName}:loadTickets`, 'Entity Id', this.entityId,
						'Store Id', this.storeId, 'Result returned', error);
					this.availableTickets = [];
					this._ticketsNextPageIndex = null;
					this._ticketSearchTerm = null;

					this.loadingTickets = false;
				},
				() => {
					this.loadingTickets = false;
				}
			);
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	 */

	/**
	 * Saves the last full search (for pre-filling this information the next time)
	 */
	private setLastFullSearchInfo() {
		this._logger.debug(`${this.componentName}:setLastFullSearchInfo`,
			'Entity', this.entityName, 'Store', this.storeName, 'Ticket', this.ticketFormatted);

		// Entity, Store and Ticket
		this._cookieService.set('QoalaTicketTrackerEntity', this.entityId.toString());
		// this._cookieService.set('QoalaTicketTrackerStore', this.storeName.toString());
		// this._cookieService.set('QoalaTicketTrackerTicket', this.ticketFormatted.toString());
	}

}
