/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @const environment
 * @description
 * Environment Variables for Development Next
 * Created by Carlos.Moreira @ 2018/07/02
 */

// Components
import { LogLevel, LogType } from 'qevo.models';
import { AppEnvironment } from '../app/core/models/configuration/app-environment.interface';
import { TakeTicketRule, TakeBookingRule } from 'qore.models';

export const environment: AppEnvironment = {
	production: true,
	hmr: false,
	envName: 'dev-next',
	configurations:
	{
		authenticationUrl: '',
		ticketTrackerUrl: 'https://dev-next.qoala.cloud/tickettracker/',
		notificationUrl: 'https://dev-next.qore.cloud/Qore.SignalR/hubs/qoalatickettracker',
		gatewayUrl: 'https://dev-next.qore.cloud/Qore.Gateway',
		bookingsGatewayUrl: 'https://dev-next-qore-bookings.azurewebsites.net/Qore.Bookings.Gateway',
		dingDongAudios: [
			{
				src: 'assets/display/audio/ding-dong.mp3',
				type: 'audio/mpeg'
			},
			{
				src: 'assets/display/audio/ding-dong.wav',
				type: 'audio/wav'
			},
			{
				src: 'assets/display/audio/ding-dong.ogg',
				type: 'audio/ogg'
			},
			{
				src: 'https://www.qore.cloud/qore.display/assets/display/audio/ding-dong.mp3',
				type: 'audio/mpeg'
			},
			{
				src: 'https://www.qore.cloud/qore.display/assets/display/audio/ding-dong.wav',
				type: 'audio/wav'
			},
			{
				src: 'https://www.qore.cloud/qore.display/assets/display/audio/ding-dong.ogg',
				type: 'audio/ogg'
			},
			{
				src: 'http://www.qore.cloud/qore.display/assets/display/audio/ding-dong.mp3',
				type: 'audio/mpeg'
			},
			{
				src: 'http://www.qore.cloud/qore.display/assets/display/audio/ding-dong.wav',
				type: 'audio/wav'
			},
			{
				src: 'http://www.qore.cloud/qore.display/assets/display/audio/ding-dong.ogg',
				type: 'audio/ogg'
			},
			{
				src: 'https://www.qevostorage.blob.core.windows.net/qore/audio/ding-dong.mp3',
				type: 'audio/mpeg'
			},
			{
				src: 'http://www.qevostorage.blob.core.windows.net/qore/audio/ding-dong.wav',
				type: 'audio/wav'
			},
			{
				src: 'http://www.qevostorage.blob.core.windows.net/qore/audio/ding-dong.ogg',
				type: 'audio/ogg'
			}
		],
		defaultLanguage: 'pt-PT',
		availableLanguages: [
			{
				id: 2070,
				display: 'PT',
				langCode: 'pt-PT',
				name: 'Português',
				country: 'Portugal',
				isDisabled: false,
				direction: 'ltr'
			},
			{
				id: 2057,
				display: 'EN',
				langCode: 'en-GB',
				name: 'English',
				country: 'United Kingdom',
				isDisabled: false,
				direction: 'ltr'
			},
			{
				id: 1034,
				display: 'ES',
				langCode: 'es-ES',
				name: 'Spanish',
				country: 'España',
				isDisabled: false,
				direction: 'ltr'
			},
			{
				id: 14337,
				display: 'AE',
				langCode: 'ar-AE',
				name: 'Arabic',
				country: 'Dubai',
				isDisabled: true,
				direction: 'rtl'
			},
			{
				id: 2035,
				display: 'FR',
				langCode: 'fr-FR',
				name: 'Français',
				country: 'France',
				isDisabled: false,
				direction: 'ltr'
			},
			{
				id: 4096,
				display: 'CA',
				langCode: 'ca-AD',
				name: 'Català',
				country: 'Andorra',
				isDisabled: false,
				direction: 'ltr'
			}
		],
		logger: {
			level: LogLevel.Trace,
			type: LogType.console,
			cache: 10,
			maxCache: 20,
			time: 1,
			loggerApiUrl: 'https://localhost:5002/logger',
			separator: '#',
			logTimingRequests: false,
			intervalToClearConsole: 60
		},
		listDefaults: {
			pageSize: 15,
			sortOrder: 'asc'
		},
		versionInfo: {
			version: '7.1.1',
			date: '2025/01/06'
		},
		notification: {
			maxNumberOfRetries: 5,
			minTimeSpanLoggedToResetMaxNumberOfRetries: 1,
			timeBetweenRetries: 10
		},
		corsAnywhereProxy: 'https://dev-cors-anywhere.azurewebsites.net/',
		googleAnalyticsId: 'G-P5M687M5M7',
		maxNumberOfTakenTickets: 2,
		takeTicketRule: TakeTicketRule.ByNumberOfTicketsByService,
		maxNumberOfTakenBookings: 2,
		takeBookingRule: TakeBookingRule.ByNumberOfBookingsByService
	}
};

